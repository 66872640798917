import {
  IS_BOLD,
  IS_CODE,
  IS_ITALIC,
  IS_STRIKETHROUGH,
  IS_SUBSCRIPT,
  IS_SUPERSCRIPT,
  IS_UNDERLINE,
} from "./RichTextNodeFormat";

export function getHtmlFromLexicalJson(data: SerializedLexicalEditorState) {
  return serialize(data?.root?.children ?? []).join("");
}

export type SerializedLexicalEditorState = {
  root: {
    type: string;
    format: string;
    indent: number;
    version: number;
    children: SerializedLexicalNode[];
  };
};

export type SerializedLexicalNode = {
  children?: SerializedLexicalNode[];
  direction: string;
  format: number;
  indent?: string | number;
  type: string;
  version: number;
  style?: string;
  mode?: string;
  text?: string;
  [other: string]: unknown;
};

type Attributes = {
  doc?: {
    value: {
      id: number;
      title: string;
      slug: string;
    };
  };
  linkType?: "custom" | "internal";
  newTab?: boolean;
  nofollow?: boolean;
  rel?: string;
  sponsored?: boolean;
  url?: string;
};

export function serialize(children: SerializedLexicalNode[]): string[] {
  return children
    .map((node): string | null => {
      if (node.type === "text") {
        //isText
        // TODO: escape HTML?
        let text = node.text;

        if (node.format & IS_BOLD) {
          text = `<strong>${text}</strong>`;
        }
        if (node.format & IS_ITALIC) {
          text = `<em>${text}</em>`;
        }

        if (node.format & IS_STRIKETHROUGH) {
          text = `<span class="line-through">${text}</span>`;
        }

        if (node.format & IS_UNDERLINE) {
          text = `<span class="underline">${text}</span>`;
        }

        if (node.format & IS_CODE) {
          text = `<code>${text}</code>`;
        }

        if (node.format & IS_SUBSCRIPT) {
          text = `<sub>${text}</sub>`;
        }

        if (node.format & IS_SUPERSCRIPT) {
          text = `<sup>${text}</sup>`;
        }

        return `${text}`;
      }

      if (!node) {
        return null;
      }

      const serializedChildren = node.children ? serialize(node.children).join("") : null;

      switch (node.type) {
        case "linebreak":
          return `<br>`;
        case "link":
          const attributes: Attributes = node.fields as Attributes;

          if (attributes.linkType === "custom") {
            return `<a href="${attributes.url}"${attributes.newTab ? ' target=_"blank"' : ""} rel="${
              attributes?.rel ?? ""
            }${attributes?.sponsored ? " sponsored" : ""}${
              attributes?.nofollow ? " nofollow" : ""
            }">${serializedChildren}</a>`;
          }

          // TODO: only works for "Pages"
          return `<a href="/${attributes.doc?.value.slug}"${attributes.newTab ? ' target=_"blank"' : ""} rel="${
            attributes?.rel ?? ""
          }${attributes?.sponsored ? " sponsored" : ""}${
            attributes?.nofollow ? " nofollow" : ""
          }">${serializedChildren}</a>`;
        case "list": //TODO handle properly, especially nested lists
          if (node.listType === "bullet") {
            return `
						<ul class="list-disc mb-4 pl-8">
						  ${serializedChildren}
						</ul>`;
          } else {
            return `
						<ol class="list-disc mb-4 pl-8">
						  ${serializedChildren}
						</ol>`;
          }
        case "listitem":
          return `
						<li>
						  ${serializedChildren}
						</li>`;
        case "heading":
          return `
								<${node.tag}>
								  ${serializedChildren}
								</${node.tag}>`;
        default: //Probably just a normal paragraph
          return `<p>${serializedChildren ? serializedChildren : "<br>"}</p>`;
      }
    })
    .filter((node) => node !== null) as string[];
}
