import { ref } from "#imports";

const anchorNavItems = ref<
  Record<
    string,
    {
      name: string;
      slug: string;
    }[]
  >
>({});

export const useAnchorNavItems = () => anchorNavItems;
